// @ts-ignore
/* eslint-disable */
// @ts-ignore
import { Card, Tag,InfiniteScroll } from 'antd-mobile';
import styles from './index.less';
import { templateList } from './service';
import { useEffect, useState } from 'react';
import { history, useLocation } from 'umi';
import Toast from "antd-mobile/es/components/toast";

export default function IndexPage() {
  const [data, setData] = useState<any[]>([]);
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    flag: false,
  });
  const [hasMore, setHasMore] = useState<boolean>(false);
  
  useEffect(() => {
    if (!params.flag) {
      loadMore({ flag: false });
    }
  }, [params]);
  const loadMore = async (pm: any = {}) => {
    if (pm.flag) {
      console.log(3333);
      setParams({ ...params, ...pm });
    }
    await loadData({ ...params, ...pm });
  };
  const loadData = async (pm: any = {}) => {
    let temp = data;
    const datas = await templateList({ ...params, ...pm });
    console.log(datas);
    
    setData([...temp, ...(datas || [])]);
    setHasMore(datas.length > 0);
  };
  return (
    <div className={styles.body}>
      <div className={styles.title}>{data&&data[0]&& data[0].userName}模板扣次</div>
      <div className={styles.content_top}>
        {data &&
          data.map((item: any, index: number) => {
            return (
              <Card
                bodyClassName={styles.customBody}
                title={item.templateName}
                onClick={()=>{
                  if(item.useTemplateNum > 0){
                    history.push('./report');
                    localStorage.setItem("topTemplateId",item.topTemplateId)
                  }else{
                    Toast.show({
                      icon: 'fail',
                      content: '暂无报告',
                    }) 
                  }
                }}
              >
                <div className={styles.content_text}>
                  模板检测次数：<span>{item.templateNum || 0}</span>
                </div>
                <div className={styles.content_text}>
                  已使用检测次数：<span>{item.useTemplateNum || 0}</span>
                </div>
                <div className={styles.content_text}>
                  剩余检测次数：<span>{(item.templateNum || 0) - (item.useTemplateNum || 0) || 0}</span>
                </div>
              </Card>
            );
          })}
          
        <InfiniteScroll
          loadMore={async () => {
            await loadMore({ pageNo: params.pageNo + 1, flag: true });
          }}
          hasMore={hasMore}
        />
      </div>
    </div>
  );
}
