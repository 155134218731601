// @ts-ignore
/* eslint-disable */
import { request } from 'umi';

export async function templateList(
  data: {
    userName?: string;
    nickname?: string;
    phone?: string;
    email?: string;
    areaCodeName?: string;
    stationName?: string;
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    flag?: boolean;
  },
  options?: { [key: string]: any },
) {
  const params = data;
  params.flag = true
  const msg = await request<{
    /** 列表的内容总数 */
    retCode?: number;
    retMsg?: string;
    data: {
      datas: any;
      totalCount: number;
    };
  }>('/api/user/template/list', {
    method: 'POST',
    data: data,
    ...(options || {}),
  });
  if (msg.retCode === 0) {
    return msg.data.datas
  } else {
    return {
      success: false,
      data: [],
      total: 0,
    };
  }
}